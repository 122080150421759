import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import graphic1 from "../../images/hmeGraphic1.svg"
import graphic2 from "../../images/hmeGraphic2.svg"
import graphic3 from "../../images/jaxjoxLogo-w.svg"
import styled from "styled-components"

const Wrapper = styled.section`
  .grid2 {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 576px) {
    .user {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
    }
    .user p {
      font-size: 12px;
    }
    .users h5 {
      font-size: 0.95rem;
    }
    .persona {
      margin-bottom: 0.5rem;
    }
    .areas {
      grid-template-columns: repeat(1, 1fr);
    }
    .screen {
      margin-bottom: 1rem;
    }
  }
`
const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <Wrapper>
      <div className="project-header">
        <StaticImage
          src="../../images/jaxjox/hero.jpg"
          alt="Project"
          placeholder="dominantColor"
          className="hero"
        />
        <div className="project-title">
          <div className="client">
            <img className="client-logo" alt="logo" src={graphic3} />
            <p>/ mobile app design</p>
          </div>
          <div className="desc">
            <h2>Connected Fitness</h2>
            <p>
              helping JAXJOX define and develop a whole new category of
              connected fitness experiences
            </p>
          </div>
        </div>
      </div>
      <div className="highlights">
        <h5>Project Scope:</h5>
        <ul className="scope">
          <li>Discovery</li>
          <li>Research</li>
          <li>Concept</li>
          <li>Design</li>
          <li>Prototype</li>
          <li>Test</li>
          <li>Build</li>
        </ul>
      </div>
      <div className="highlights">
        <h5>Overview:</h5>
        <p>
          The JAXJOX App connects with JAXJOX connected fitness products and
          other fitness tracking devices such as Fitbit. All of the data
          collected from these devices creates a user’s Fitness iQ score
          anddetermines their overall progression towards a health goal. The app
          also hosts a series of workouts reserved for JAXJOX product owners and
          even provides recommendations to help reach a goal.
        </p>
      </div>
      <StaticImage
        src="../../images/jaxjox/jj-product.jpg"
        alt="Project"
        placeholder="blurred"
        quality={100}
        className="screen"
      />
      <div className="highlights">
        <h5>Key goals:</h5>
        <ul>
          <li>
            Drive the concept and design of an all-new fitness experience based
            on sports science research
          </li>
          <li>
            Develop proprietary algorithm that tracks changes in fitness based
            on product usage
          </li>
          <li>
            Create a simple. intuitive “fitness IQ” meter that provides
            at-a-glance progress against goals
          </li>
          <li>
            Design, build and release multi-device fitness dashboard application
          </li>
        </ul>
      </div>

      <div className="key-areas">
        <h2>PROJECT ENGAGEMENT / 6 months</h2>
        <div className="areas areas2">
          <div className="area">
            <h4>CONSULTING TEAM (project202)</h4>
            <ul>
              <li>Account Exective</li>
              <li>Project Manager</li>
              <li>Experience Design Director (Ned Cannon)</li>
              <li>Visual Design Lead (Ned Cannon)</li>
              <li>App Developer(s)</li>
            </ul>
          </div>

          <div className="area">
            <h4>CLIENT STAKEHOLDERS (JAXJOX)</h4>
            <ul>
              <li>President / CEO</li>
              <li>Lead Product Manager</li>
              <li>Manufacturing group</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="screens">
        <div className="project-label">
          <p>Initial Dashboard Visual Design Concepts</p>
        </div>
        <StaticImage
          src="../../images/jaxjox/jj-2.jpg"
          quality={100}
          alt="Project"
          placeholder="dominantColor"
          className="screen"
        />

        <div className="project-label">
          <p>Final Design</p>
        </div>
        <StaticImage
          src="../../images/jaxjox/jj-3.jpg"
          alt="Project"
          quality={100}
          placeholder="dominantColor"
          className="screen"
        />

        <div className="project-label">
          <p>Marketing Design Direction</p>
        </div>
        <StaticImage
          src="../../images/jaxjox/jj-4.jpg"
          alt="Project"
          quality={100}
          placeholder="dominantColor"
          className="screen"
        />
      </div>

      <div className="footer-nav">
        <Link to="/">&#x2190; Recent work</Link>
        <span></span>
        <Link to="/work/mytennisroster">Next Project &#8594; </Link>
      </div>
    </Wrapper>
  </Layout>
)

export default SecondPage
